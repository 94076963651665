import * as React from 'react';
import classnames from 'classnames';
import {IPropsInjectedByViewerScript, IProductWidgetSantaProps} from '../../types/app-types';
import s from './ProductWidgetApp.scss';
import {ProductWidgetLayout} from '../ProductWidgetLayout/ProductWidgetLayout';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import '../../styles/app.global.scss';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ReactComponent as NoProduct} from '../../icons/NoProduct.svg';
import {getProductWidgetSettingsFromProps} from '../../commons/styleParamsService';

export type IProductWidgetAppProps = IPropsInjectedByViewerScript &
  IProvidedGlobalProps &
  IProductWidgetSantaProps &
  IProvidedTranslationProps;

export enum DataHook {
  Root = 'product-widget',
  EmptyState = 'empty-state',
  EmptyStateText = 'empty-state-text',
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductWidgetApp extends React.Component<IProductWidgetAppProps> {
  public componentDidMount(): void {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IProductWidgetAppProps): void {
    const productWidgetPrevProps = getProductWidgetSettingsFromProps(prevProps);
    const productWidgetProps = getProductWidgetSettingsFromProps(this.props);
    if (this.props.host.viewMode === 'Editor' && productWidgetPrevProps.fullWidth && !productWidgetProps.fullWidth) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.props.host.resizeComponent({width: 940, height: 400});
      }, 1000);
    }
  }

  private readonly reportAppLoaded = () => {
    this.safeRun(this.props.onAppLoaded);
  };

  private safeRun(fn) {
    if (this.props.globals.isInteractive && typeof fn === 'function') {
      fn();
    }
  }

  private get isReady() {
    return this.props.globals.product !== undefined;
  }

  public render(): React.ReactNode {
    const {
      horizontalLayout,
      dimensions: {height, width},
    } = this.props.globals;
    const {emptyState, t} = this.props;

    if (!this.isReady) {
      return null;
    }

    return (
      <section
        style={{width, height}}
        data-hook={DataHook.Root}
        className={classnames(s.productWidgetApp, {
          [s.horizontal]: horizontalLayout,
        })}>
        {emptyState ? (
          <section className={s.emptyState} data-hook={DataHook.EmptyState}>
            <NoProduct />
            <p data-hook={DataHook.EmptyStateText}>{t('EMPTY_STATE')}</p>
          </section>
        ) : (
          <ProductWidgetLayout />
        )}
      </section>
    );
  }
}
